<template>
  <div>
    <div class="p-4">
      <div class="row">
        <div class="col-md-12">
          <h2 class="h2 h2-dark mb-3">Veuillez finaliser l’inscription</h2>
        </div>
      </div>
    </div>
    <div class="bloc-primary-light d-flex justify-content-center p-5">
      <h4 class="h4 text-primary fw-bold">
        Merci pour la validation de votre email.<br />Vous devez terminer de
        remplir le formulaire pour finaliser votre dossier et pouvoir accéder à
        votre espace personnel pour ouvrir des droits à l’inscription aux
        sessions</h4
      >
    </div>
    <div class="p-4">
      <div class="row mb-5">
        <div class="col-md-12">
          <atom-button
            @click="next"
            btn-class="btn btn-primary m-auto mt-5"
            type="button"
            title="Compléter les informations"
          />
        </div>
      </div>
    </div>
    <support-box />
  </div>
</template>

<script>
import { mapFields } from 'vuex-map-fields'
import AtomButton from '@/components/atoms/AtomButton'
import SupportBox from '@/components/SupportBox.vue'
export default {
  name: 'WaitingForValidation',
  components: {
    AtomButton,
    SupportBox,
  },
  data() {
    return {}
  },
  computed: {
    ...mapFields('app', ['user']),
    ...mapFields('inscription', ['steps', 'currentStep']),
  },
  created() {
    const support = {
      header: 'Vous y êtes presque !',
      content: ``,
    }
    this.$store.commit('app/SET_SUPPORT', support)
    this.$store.commit('app/SHOW_SUPPORT')
    window.scrollTo(0, 0)
  },
  methods: {
    next() {
      let path = window.location.search
      let token = {
        'access-token': path.substr(14, window.location.search.length - 14),
      }
      this.$store.dispatch('app/confirmUserAccount', token).then(() => {
        this.steps[0].status = 'completed'
        this.currentStep = this.steps[1]
      })
    },
  },
}
</script>

<style></style>
