import Vue from 'vue'
/*
 *
 */
const EventBus = new Vue({
  data() {
    return {
      channels: [],
    }
  },
  created() {},
})
export default EventBus
