<template>
  <div>
    <label class="col-form-label" :class="labelClass" for="">{{ label }}</label>
    <input
      :type="type"
      :class="inputClass"
      :value="value"
      :placeholder="placeholder"
      :pattern="pattern"
      :disabled="disabled"
      v-bind="$attrs"
      @input="handleInput($event.target.value)"
    />
  </div>
</template>

<script>
export default {
  name: 'AtomInput',
  components: {},
  props: {
    value: {
      type: String,
      required: true,
    },
    label: {
      type: String,
      required: false,
      default: '',
    },
    type: {
      type: String || Number,
      required: true,
    },
    inputClass: {
      required: true,
    },
    labelClass: {
      required: false,
      default: 'label-dark',
    },
    placeholder: {
      type: String,
      required: false,
      default: '',
    },
    pattern: {
      type: String,
      required: false,
      default: '',
    },

    disabled: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {}
  },
  mounted() {},
  methods: {
    handleInput(value) {
      this.$emit('input', value)
    },
  },
}
</script>
