import { getField, updateField } from 'vuex-map-fields'
// import {} from '@/store/mutation-types'
import { resendEmail as forwardingEmail } from '@/api/inscription.js'
const state = {
  steps: [
    {
      number: 1,
      title: 'Votre compte',
      component: 'One',
      status: '',
    },
    {
      number: 2,
      title: 'Votre profil',
      component: 'Two',
      status: '',
    },
    // {
    //   number: 3,
    //   title: 'Vos expériences',
    //   component: 'Three',
    //   status: '',
    // },
    // {
    //   number: 4,
    //   title: 'Autres  informations',
    //   component: 'Four',
    //   status: '',
    // },
    {
      number: 3,
      title: 'Compte créé',
      component: 'Four',
      status: '',
    },
  ],
  currentStep: {
    number: '',
    title: '',
    component: '',
    status: '',
  },
  errorModal: '',
  emailConfirmed: false,
  resendEmail: false,
}

const mutations = {
  updateField,
}

const actions = {
  resendEmailConfirmation({ state }, params = {}) {
    return forwardingEmail(params).then((response) => {
      if (response) {
        state.resendEmail = true
        return response
      }
    })
  },
}

const getters = { getField }

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
}
