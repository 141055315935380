<template>
  <div>
    <div class="info-bulle"
      ><div class="text-center">{{ nurseMessage }}</div></div
    >
    <div class="nurse"> </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { mapFields } from 'vuex-map-fields'
export default {
  name: 'Nurse',
  computed: {
    ...mapGetters('cart', ['cartTotalItems']),
    ...mapFields('cart', ['nurseMessage']),
  },
}
</script>
<style lang="scss" scoped>
.info-bulle {
  display: flex;
  justify-content: center;
  align-items: center;
  width: fit-content;
  aspect-ratio: 355 / 251;
  background-image: url(../assets/nurse-info-bulle.svg);
  background-size: cover;
  background-repeat: no-repeat;
  text-transform: uppercase;
  padding: 1rem 1rem 3rem;
}
.nurse {
  width: 60px;
  aspect-ratio: 93 / 270;
  background-image: url(../assets/nurse.svg);
  background-size: cover;
  background-repeat: no-repeat;
  @media (min-width: 992px) {
    width: 70px;
  }
}
</style>
