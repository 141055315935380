<template>
  <div class="form-check">
    <input
      class="form-check-input"
      type="checkbox"
      :value="value"
      :id="label"
      @change="handleCheck($event.target.checked)"
    />
    <label v-if="link" :class="labelClass" :for="label">
      <a :href="link" target="_blank">{{ label }}</a>
    </label>
    <label v-else :class="labelClass" :for="label">
      {{ label }}
    </label>
  </div>
</template>

<script>
import { mapFields } from 'vuex-map-fields'
export default {
  name: 'AtomCheckbox',
  props: {
    value: {
      required: true,
    },
    label: {
      required: false,
      default: '',
    },
    link: {
      type: String,
      required: false,
      default: '',
    },
    labelClass: {
      required: false,
      default: '',
    },
  },
  data() {
    return {}
  },
  computed: {
    ...mapFields(['']),
  },
  mounted() {},
  methods: {
    handleCheck(value) {
      this.$emit('input', value)
    },
  },
}
</script>
