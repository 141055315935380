import { getField, updateField } from 'vuex-map-fields'
import { getUserOrders as getOrders } from '@/api/trainings.js'
import { SET_TRAININGS } from '@/store/mutation-types'
import lodash from 'lodash-es'
const state = {
  userTrainings: [],
}

const mutations = {
  updateField,
  [SET_TRAININGS](state, data) {
    state.userTrainings = data
  },
}

const actions = {
  async getUserOrders({ commit }, params = {}) {
    const res = await getOrders(params)
    let sessions = []
    if (res) {
      sessions = lodash.filter(res.data.sessions, (session) => {
        return session.status != -1
      })
      commit('SET_TRAININGS', sessions)
    }
    return sessions
  },
}

const getters = { getField }

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
}
