import axios from 'axios'
// create an axios instance
const service = axios.create({
  // withCredentials: true, // send cookies when cross-domain requests
  timeout: 30000, // request timeout
})

// request interceptor
service.interceptors.request.use(
  (config) => {
    return config
  },
  (error) => {
    return Promise.reject(error)
  }
)

// response interceptor
service.interceptors.response.use(
  async (response) => {
    const res = response.data
    if (
      response.status != 200 &&
      response.status != 201 &&
      response.status != 204
    ) {
      return Promise.reject(new Error(response.message || 'Error'))
    } else {
      return res
    }
  },
  async (error) => {
    return Promise.reject(error)
  }
)

export default service
