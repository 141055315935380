<template>
  <div class="p-4">
    <div>
      <h2 class="h2-dark mb-5"
        >Félicitations, votre espace personnel a bien été créé !</h2
      >
      <h4 class="h4 text-primary mb-4">
        Merci pour ces précieuses information votre inscription est désormais
        effective. Vous pouvez consulter le catalogue et vous inscrire aux
        sessions de formations souhaitées.
      </h4>
      <div class="row mt-4 mb-5">
        <div
          class="align-items-center col-lg-12 col-sm-12 d-flex flex-wrap gap-2 justify-content-end justify-content-lg-start mb-5"
        >
          <svg-icon icon-class="hand" class-name="hand-icon" />
          <atom-button
            @click="goToMyAccount"
            btn-class="btn btn-primary ms-3 pe-4 ps-3"
            type="button"
            title="Espace personnel"
          />

          <atom-button
            @click="goHome"
            btn-class="btn btn-primary ms-3 pe-2 ps-2"
            type="button"
            title="Trouver votre formation"
          />
        </div>
      </div>
    </div>
    <support-box />
    <error-modal />
  </div>
</template>

<script>
import { mapFields } from 'vuex-map-fields'
import AtomButton from '@/components/atoms/AtomButton'
import SupportBox from '@/components/SupportBox.vue'
import ErrorModal from '@/apps/signup/components/modals/ErrorModal.vue'
export default {
  name: 'Four',
  components: {
    AtomButton,
    SupportBox,
    ErrorModal,
  },
  data() {
    return {}
  },
  computed: {
    ...mapFields('app', ['isAuthenticated', 'user']),
  },
  created() {
    const support = {
      header: '',
      content: `Merci d’avoir complété  le questionnaire ! Vos informations sont en cours de vérification. Le catalogue vous est désormais accessible pour toute inscription !`,
    }
    this.$store.commit('app/SET_SUPPORT', support)
    this.$store.commit('app/SHOW_SUPPORT')
    window.scrollTo(0, 0)
  },
  mounted() {
    this.isAuthenticated = true
  },
  methods: {
    goToMyAccount() {
      //login on behalf user
      let id = this.user.id
      this.$store
        .dispatch('app/loginOnbehalfUser', id)
        .then(() => {})
        .finally(() => {
          window.location = '/mon-compte#/mon-compte'
        })
    },
    goHome() {
      //login on behalf user
      let id = this.user.id
      this.$store
        .dispatch('app/loginOnbehalfUser', id)
        .then(() => {})
        .finally(() => {
          window.location = '/formations'
        })
      window.location = '/formations'
    },
  },
}
</script>

<style lang="scss" scoped>
.hand-icon {
  width: 84px !important;
  height: 51px !important;
}
</style>
