<template>
  <div
    class="modal fade"
    id="inscription-error-modal"
    data-bs-backdrop="static"
    data-bs-keyboard="false"
    tabindex="-1"
    aria-labelledby="cartModalLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog errorModal modal-dialog-centered">
      <div class="modal-content border-0">
        <div class="modal-header border-0">
          <h5 class="modal-title" id="cartModalLabel"></h5>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div class="modal-body pt-4">
          <div class="row">
            <div class="col-lg-6 col-sm-12 mb-3">
              <h1 class="h1-primary mb-5">Inscription</h1>
              <h4 class="h4-dark fw-bold mb-4">
                {{ message }}
              </h4>
            </div>
          </div>
          <div class="row d-block">
            <div class="col-sm-6 col-lg-5 mb-3">
              <atom-button
                @click="goToCart"
                btn-class="btn btn-primary  w-100"
                type="button"
                title="Nouveau compte"
              />
            </div>
            <div class="col-sm-6 col-lg-5 mb-2">
              <atom-button
                @click="login"
                btn-class="btn btn-dark  w-100"
                type="button"
                title="Se connecter"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import AtomButton from '@/components/atoms/AtomButton'
import { mapFields } from 'vuex-map-fields'
export default {
  name: 'ErrortModal',
  components: { AtomButton },
  data() {
    return {
      session: '',
      sessionsOptions: [],
      message: '',
    }
  },
  computed: {
    ...mapFields('cart', ['cartModal']),
    ...mapFields('inscription', ['errorModal']),
    ...mapFields('app', ['isCardLoginIsShowing']),
  },
  mounted() {
    this.$bus.$on('error-modal.show', (data) => {
      this.message = data.message
      this.errorModal.show()
    })
    this.$nextTick(() => {})
  },
  methods: {
    goToCart() {
      window.location = '/inscription'
    },
    login() {
      this.isCardLoginIsShowing = true
      this.errorModal.hide()
    },
  },
}
</script>

<style lang="scss" scoped>
.modal-content {
  background-color: #f4f8fa !important;
  border-radius: 15px !important;
}
</style>
