<template>
  <div class="support-box">
    <div class="support-box__body" v-if="support && support.show">
      <div class="support-box__content">
        <div class="support-box__head">{{ support.header }}</div>
        <div class="support-box__content">{{ support.content }}</div>
        <div class="support-box__pict">
          <img src="../assets/box-avatar.svg" alt="" />
        </div>
      </div>
    </div>
    <div class="support-box__footer">
      <button class="support-box__btn" @click="showHide">
        <svg-icon
          v-if="!support.show"
          icon-class="info-solid"
          className="info-solid"
        />
        <svg-icon v-else icon-class="times" className="times" />
      </button>
    </div>
  </div>
</template>

<script>
import Cookies from 'js-cookie'
import { mapFields } from 'vuex-map-fields'
export default {
  name: 'SupportBox',
  components: {},
  data() {
    return {}
  },
  computed: {
    ...mapFields('app', ['support']),
  },
  mounted() {},
  methods: {
    showHide() {
      if (Cookies.get('hide-support') == 'true') {
        this.$store.commit('app/SHOW_SUPPORT')
      } else {
        this.$store.commit('app/HIDE_SUPPORT')
      }
    },
  },
}
</script>
<style scoped>
.times {
  position: absolute;
  right: 25%;
  top: 25%;
  color: black !important;
  width: 1.5rem;
  height: 1.5rem;
}

.info-solid {
  position: absolute;
  top: 25%;
  right: 25%;
  color: black !important;
  width: 1.5rem;
  height: 1.5rem;
}
</style>
